export const baseRentData = [
    {
        id: 0,
        label: '',
        value: ''
    },
    {
        id: 1,
        label: '',
        value: ''
    }
];

export const baseExpensesData = [
    {
        id: 0,
        label: '',
        value: ''
    },
    {
        id: 1,
        label: '',
        value: ''
    },
];

export const baseResultData = [
    {
        label: '',
        amount: '',
        percentage: ''
    },
    {
        label: '',
        amount: '',
        percentage: ''
    }
];

export const currencyList = [
    '$',
    '£',
    '¥',
    '€',
];

export const categoryMetaDesc = {
    'budgeting-tips': 'Explore practical budgeting tips and tricks to manage your expenses effectively. Learn how to create and stick to a budget, save money on rent, bills, and calculate your income for a more secure financial future.',
    'financial-planning': 'Dive into comprehensive financial planning guides to secure your future. From setting goals and managing debt to investment strategies, get expert insights on expenses, bills, rent, and income to make informed financial decisions.',
    'saving-strategies': 'Master the art of saving with strategies that extend beyond the ordinary. Our articles provide actionable tips on how to save for bills, rent, and other expenses, helping you build a resilient financial future.',
}
