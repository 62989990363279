import { Link } from "react-router-dom"

export default function ErrorPage() {
    return (
        <div className="m-auto max-w-[480px] md:max-w-[800px] p-5 flex flex-col items-center py-24">
            <p className="text-6xl text-center font-bold mb-4">404</p>
            <p className='text-center text-slate-600 mb-4'>Looks like this page doesnt exist.</p>
            <Link to={'/'} className="font-bold text-primary mt-4">
                <button className="btn btn-primary text-white rounded-full btn-md shadow btn-wide mt-6">Return To Home Page</button>
            </Link>
        </div>
    )
}