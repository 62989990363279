import './info-icon.styles.scss';
import {useState} from "react";

function Icon() {
    return(
            <svg className="icon" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.0374 28.6035C26.1039 27.9717 26.0374 27.4896 25.8379 27.1571C25.6717 26.8246 25.3558 26.6584 24.8903 26.6584C24.5245 26.6584 24.0756 26.7249 23.5436 26.8579C23.0449 26.9909 22.4796 27.1737 21.8479 27.4065L21 24.7132C21.7315 24.3475 22.7955 23.9485 24.192 23.5162C25.6218 23.0507 27.1845 22.818 28.8803 22.818C30.2436 22.818 31.4073 23.0673 32.3716 23.5661C33.3358 24.0316 34.0507 24.7631 34.5162 25.7606C34.9817 26.7249 35.148 27.9717 35.015 29.5012L34.0673 43.3167C34.0341 44.1147 34.084 44.7132 34.217 45.1122C34.3832 45.5112 34.7323 45.7107 35.2643 45.7107C35.7298 45.7107 36.212 45.611 36.7107 45.4115C37.2095 45.1787 37.675 44.9293 38.1072 44.6633L38.9052 47.0075C38.4397 47.3732 37.7415 47.7889 36.8105 48.2544C35.9127 48.7199 34.8986 49.1189 33.7681 49.4514C32.6708 49.8171 31.5902 50 30.5262 50C28.9967 50 27.7997 49.7506 26.9352 49.2519C26.0707 48.7531 25.4888 48.005 25.1895 47.0075C24.8903 45.9767 24.7905 44.6966 24.8903 43.1671L26.0374 28.6035ZM25.2893 14.5885C25.2893 13.2585 25.7548 12.1613 26.6858 11.2968C27.6168 10.4323 28.8969 10 30.5262 10C31.5569 10 32.4547 10.1995 33.2195 10.5985C34.0175 10.9975 34.6326 11.5461 35.0648 12.2444C35.5303 12.9426 35.7631 13.7406 35.7631 14.6384C35.7631 15.9684 35.281 17.0657 34.3167 17.9302C33.3525 18.7947 32.1056 19.2269 30.5761 19.2269C28.9468 19.2269 27.65 18.7947 26.6858 17.9302C25.7548 17.0324 25.2893 15.9185 25.2893 14.5885Z"  fill="var(--primary)"/>
            </svg>
    )
}

function InfoIcon({infoText}) {
    const [showText, setShowText] = useState(false)
    function hideShowText(value) {
        setShowText(value)
    }

    return (
        <div className="info-icon">
            <button
                className="button"
                onMouseEnter={() => hideShowText(true)}
                onMouseLeave={() => hideShowText(false)}
                onClick={() => hideShowText(!showText)}
            >
                <Icon/>
            </button>
            { showText &&
                <div className="text">
                    {infoText}
                </div>
            }
        </div>
    )
};

export default InfoIcon;