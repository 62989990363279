import Footer from "../components/Footer/Footer";
import GlobalNav from "../components/GlobalNav/GlobalNav";
import FeedbackForm from "../components/FeedbackForm/FeedbackForm";
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";

export default function Root() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname]);

    return (
        <div>
            <GlobalNav/>
            <Outlet/>
            <FeedbackForm/>
            <Footer/>
        </div>
    );
}
