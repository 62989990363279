import './default-card.styles.scss'
import InfoIcon from "../InfoIcon/InfoIcon.component";

function DefaultCard({children, title, infoText}) {
    return (
        <div className={'default-card shadow-xl'}>
            { title &&
                <p className='title text-lg md:text-xl'>{title}</p>
            }

            { infoText &&
                <InfoIcon
                    infoText={infoText}
                />
            }

            {children}
        </div>
    )
}

export default DefaultCard;