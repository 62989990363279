import './alert-popup.styles.scss'
import {useEffect, useState} from "react";

function AlertPopup({message, handleUnmount, type}) {
    const [showAlert, setShowAlert] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setShowAlert(false)
        }, 5000)
    })

    useEffect(() => {
        if (!showAlert) {
            handleUnmount(false)
        }
    }, [showAlert])

    function typeHandler() {
        if (type === 'warning') {
            return '⚠'
        }
        if (type === 'success') {
            return '✓'
        }
    }

    return showAlert ? (
        <div className={`alert-popup __${type}`} onClick={() => handleUnmount(false)}>
           <span>{typeHandler()}</span>{message}
        </div>
    ) : null
}

export default AlertPopup;