import AlertPopup from "../../components/AlertPopup/AlertPopup.component";
import { copyToClipboard } from "../../mixins/copyToClipboard";
import { useState } from "react";
import emailAddress from '../../assets/email-address.svg';

export default function FeedbackForm() {
    const [alertStack, setAlertStack] = useState([])
    function newAlert(message, type) {
        let newAlertStack = [...alertStack]
            newAlertStack.push({message: message, shouldShow: true, type: type})
        setAlertStack(newAlertStack)
    }

    function removeAlert(index) {
        let removeEl = alertStack.filter(el => el['shouldShow'] !== true)
        setAlertStack(removeEl)
    }

    const email = 'fairsplitcalculator@gmail.com'

    return (
        <div className='flex flex-col items-center mb-12 px-5'>
            {alertStack.map((item, index) => (
                item['shouldShow']
                    ? (<AlertPopup message={item.message} key={index} handleUnmount={() => removeAlert(index)} type={item.type} />)
                    : null
            ))}

            <h3 className='text-2xl font-bold text-center mb-6'>Have any suggestions to improve this tool?</h3>
            <p className='w-[240px] text-center text-sm text-slate-600 mb-4'>Don't hesitate to get in touch and let us know your thoughts!</p>
            {/* <textarea placeholder="☺" className="textarea textarea-bordered textarea-md w-full max-w-md" ></textarea> */}
            <div className='bg-[#EEEEF8] rounded-lg py-2 px-4 flex items-center text-sm font-bold'>
                <img src={emailAddress} alt="email address" />
                <button onClick={() => {copyToClipboard(email); newAlert('Copied to clipboard.', 'success');}} className="btn btn-circle btn-sm btn-primary text-white ml-2">
                    <svg className="h-5 w-5" viewBox="-4 -2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 11.8125H6.75C6.44062 11.8125 6.1875 11.5594 6.1875 11.25V2.25C6.1875 1.94062 6.44062 1.6875 6.75 1.6875H11.6754L14.0625 4.07461V11.25C14.0625 11.5594 13.8094 11.8125 13.5 11.8125ZM6.75 13.5H13.5C14.741 13.5 15.75 12.491 15.75 11.25V4.07461C15.75 3.62813 15.5707 3.19922 15.2543 2.88281L12.8707 0.495703C12.5543 0.179297 12.1254 0 11.6789 0H6.75C5.50898 0 4.5 1.00898 4.5 2.25V11.25C4.5 12.491 5.50898 13.5 6.75 13.5ZM2.25 4.5C1.00898 4.5 0 5.50898 0 6.75V15.75C0 16.991 1.00898 18 2.25 18H9C10.241 18 11.25 16.991 11.25 15.75V14.625H9.5625V15.75C9.5625 16.0594 9.30938 16.3125 9 16.3125H2.25C1.94062 16.3125 1.6875 16.0594 1.6875 15.75V6.75C1.6875 6.44062 1.94062 6.1875 2.25 6.1875H3.375V4.5H2.25Z" fill="currentColor"/>
                    </svg>
                </button>
            </div>
        </div>
    )
};
