import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function PrivacyPolicy() {
    return (
        <div className="privacy-page">
            <HelmetProvider>
                <Helmet>
                    <title>Fair Split Calculator – Privacy Policy</title>
                </Helmet>
            </HelmetProvider>

            <section className="main-section">
                <h1 className="text-4xl font-bold text-center mb-12">Privacy Policy</h1>

                <div className="flex flex-col gap-y-2 mb-12">
                    <p>This Privacy Policy outlines how www.fairsplitcalc.com collects, uses, discloses, and safeguards your personal information when you visit our website.</p>

                    <h3 className="text-lg mt-2">1. Information We Collect</h3>

                    <p>When you access our website, we may automatically collect certain information about your device, browsing actions, and patterns, including:</p>

                    <ul className="pl-5 ml-2.5 list-disc">
                        <li>Internet Protocol (IP) address</li>
                        <li>Browser type and version</li>
                        <li>Date and time of your visit</li>
                        <li>Pages visited</li>
                        <li>Referring/exit pages</li>
                        <li>Clickstream data</li>
                        <li>Other similar data and information</li>
                        <li>We collect this data for analytical purposes using Google Analytics.</li>
                    </ul>

                    <h3 className="text-lg mt-2">2. Google Analytics</h3>

                    <p>We use Google Analytics, a web analytics service provided by Google, Inc. ("Google"), to collect and analyze data about your use of our website. Google Analytics uses cookies to collect information, including your IP address. This information is transmitted to Google, where it is processed to generate statistical reports on website activity.</p>

                    <h3 className="text-lg mt-2">3. How We Use Your Information</h3>

                    <p>The information collected through Google Analytics is used for the sole purpose of improving our website's content and functionality. We do not use this data to personally identify individuals. The aggregated data helps us understand how visitors use our website, enabling us to enhance the user experience.</p>

                    <h3 className="text-lg mt-2">4. Cookies</h3>

                    <p>We use cookies on our website to collect and store information about your preferences and activities. Cookies are small files stored on your device that help us improve your experience. You can disable cookies through your browser settings, but this may affect the functionality of our website.</p>

                    <h3 className="text-lg mt-2">5. Third-Party Links</h3>

                    <p>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. Please review the privacy policies of those sites before providing any personal information.</p>

                    <h3 className="text-lg mt-2">6. Your Choices</h3>

                    <p>You can opt-out of Google Analytics by using the Google Analytics Opt-out Browser Add-on, available at https://tools.google.com/dlpage/gaoptout.</p>

                    <h3 className="text-lg mt-2">7. Changes to this Privacy Policy</h3>

                    <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website.</p>
                </div>
            </section>
        </div>
    )
}