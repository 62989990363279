import './cookie-popup.styles.scss'
import DefaultButton from "../DefaultButton/DefaultButton.component";
import CookieIcon from '../../assets/cookie.svg'

function CookiePopup({acceptCookie}) {
    function storeCookieConsent() {
        window.localStorage.setItem('cookieConsent', 'true')
        acceptCookie()
    }

    return(
        <div className="cookie-popup">
            <div className="container">
                <img src={CookieIcon} alt="cookie icon" width={40} height={40}/>
                <span>
                    This website uses cookies.
                    By continuing to use this website, you agree to the use of cookies.
                </span>
                <DefaultButton className='w-12' onClick={() => storeCookieConsent()}>OK</DefaultButton>
            </div>
        </div>
    )
}

export default CookiePopup