import { Adsense } from '@ctrl/react-adsense';
import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import updateMetaDescription from '../mixins/updateMetaDescription';

export default function PercentageCalculator() {
    updateMetaDescription('Calculate percentages with our free Percentage Calculator. Get the percentages of a single number, the percentage increase or decrease of a value, or the percentage change between two values.')

    const [inputX1, setInputX1] = useState('');
    const [inputY1, setInputY1] = useState('');
    const [percentageResult1, setPercentageResult1] = useState(0);
    
    useEffect(
        function calculatePercentage1() {
            const percentageValue = inputX1 / 100;
            const result = inputY1 * percentageValue
            setPercentageResult1(result)
        }, 
        [inputX1, inputY1]
    );

    const [inputX2, setInputX2] = useState('');
    const [inputY2, setInputY2] = useState('');
    const [percentageResult2, setPercentageResult2] = useState(0);

    useEffect(
        function calculatePercentage2() {
            if (inputX2 && inputY2) {
                const percentageValue = inputX2 / inputY2;
                const result = percentageValue * 100;
                setPercentageResult2(result)
            }
        }, 
        [inputX2, inputY2]
    );

    const [inputX3, setInputX3] = useState('');
    const [inputY3, setInputY3] = useState('');
    const [percentageResult3, setPercentageResult3] = useState(0);

    useEffect(
        function calculatePercentage3() {
            if (inputX3 && inputY3) {
                const difference = inputY3 - inputX3;
                const result = (difference / inputX3) * 100;
                setPercentageResult3(result)
            }
        }, 
        [inputX3, inputY3]
    );

    return (
        <div className="mx-auto p-5">
            <HelmetProvider>
                <Helmet>
                <title>Percentage Calculator – Fair Split Calculator</title>
                </Helmet>
            </HelmetProvider>

			<div className='flex m-auto lg:grid grid-cols-[1fr_800px_1fr] max-w-[1390px]'>
                <div className="hidden lg:block w-full p-5 text-right h-full">
                    <Adsense
                        data-ad-client="ca-pub-9750567100834191"
                        slot="2679096007"
                        style={{ display: 'block', position: 'sticky', top: '90px' }}
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    />
                </div>

                <div className='w-full max-w-[460px] min-[520px]:max-w-[800px] mx-auto'>
                    <section className="mb-4 flex flex-col items-center">
						<h1 className="text-4xl text-center font-bold mb-4">Percentage Calculator</h1>
					</section>

                    <section className='mb-12 max-w-[600px] m-auto'>
                        <div className="card w-full shadow-xl bg-white mb-4">
                            <div className="card-body px-4">
                                <div className='flex justify-between'>
                                    <div className='flex flex-wrap gap-2.5 items-center'>
                                        <input
                                            onChange={(e) => setInputX1(e.target.value)}
                                            type="number" 
                                            className="input h-10 input-bordered w-20 min-[360px]:w-24 min-[390px]:w-28 min-[430px]:w-32 min-[480px]:w-24 md:w-32 max-w-xs px-2 focus:outline-primary"
                                            placeholder='x'
                                        />
                                        <span>% of</span>
                                        <input
                                            onChange={(e) => setInputY1(e.target.value)}
                                            type="number"
                                            className="input h-10 input-bordered w-28 min-[360px]:w-32 min-[390px]:w-36 min-[430px]:w-44 min-[480px]:w-28 min-[576px]:w-40 md:w-44 max-w-xs px-2 focus:outline-primary"
                                            placeholder='y'
                                        />
                                        <div className='flex items-center gap-2.5'>
                                            <span>is</span>
                                            <input
                                                value={percentageResult1 === 0 ? '' : percentageResult1}
                                                type="number" 
                                                className="input h-10 w-40  min-[430px]:w-44  min-[480px]:w-28 min-[576px]:w-40 md:w-44 max-w-xs input-bordered bg-white px-2 focus:outline-primary"
                                                readOnly
                                                placeholder='?'
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card w-full shadow-xl bg-white mb-4">
                            <div className="card-body px-4">
                                <div className='flex justify-between'>
                                    <div className='flex flex-wrap gap-2.5 items-center'>
                                        <input
                                            onChange={(e) => setInputX2(e.target.value)}
                                            type="number"
                                            className="input h-10 input-bordered w-28 min-[360px]:w-32 min-[390px]:w-36 min-[430px]:w-40 min-[480px]:w-28 min-[576px]:w-36 md:w-40 max-w-xs px-2 focus:outline-primary"
                                            placeholder='x'
                                        />
                                        <span>is</span>
                                        <div className='flex items-center gap-2.5'>
                                            <input
                                                type="number"
                                                value={percentageResult2 === 0 ? '' : percentageResult2}
                                                className="input h-10 w-20 min-[360px]:w-24 min-[390px]:w-28 min-[430px]:w-32 min-[480px]:w-20 min-[576px]:w-28 md:w-32 input-bordered max-w-xs bg-white px-2 focus:outline-primary"
                                                readOnly
                                                placeholder='?'
                                            />
                                            <span>%</span>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <span>of</span>
                                            <input
                                                onChange={(e) => setInputY2(e.target.value)}
                                                type="number"
                                                className="input h-10 input-bordered w-32 min-[360px]:w-36 min-[390px]:w-40 min-[430px]:w-44 min-[480px]:w-28 min-[576px]:w-36 md:w-44 max-w-xs px-2 focus:outline-primary"
                                                placeholder='y'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card w-full shadow-xl bg-white">
                            <div className="card-body px-4">
                                <div className='flex justify-between'>
                                    <div className='flex flex-wrap gap-2.5 items-center'>
                                        <input
                                            onChange={(e) => setInputX3(e.target.value)}
                                            type="number"
                                            className="input h-10 input-bordered w-20 min-[360px]:w-24 min-[390px]:w-28 min-[430px]:w-32 min-[576px]:w-fit md:w-28 max-w-xs px-2 focus:outline-primary"
                                            placeholder='x'
                                        />
                                        <span>to</span>
                                        <input
                                            onChange={(e) => setInputY3(e.target.value)}
                                            type="number"
                                            className="input h-10 input-bordered w-20 min-[360px]:w-28 min-[390px]:w-32 min-[430px]:w-36 min-[576px]:w-fit md:w-28 max-w-xs px-2 focus:outline-primary"
                                            placeholder='y'
                                        />
                                        <span>is a</span>
                                        <div className='flex items-center gap-2.5'>
                                            <input
                                                type="number"
                                                value={percentageResult3 === 0 ? '' : percentageResult3}
                                                className="input h-10 w-20 min-[360px]:w-28 min-[390px]:w-32 min-[430px]:w-40 min-[576px]:w-fit md:w-24 input-bordered bg-white max-w-xs px-2 focus:outline-primary text-primary"
                                                readOnly
                                                placeholder='?'
                                            />
                                            <span className='whitespace-nowrap'>% increase/decrease</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
				</div>

				<div className="hidden lg:block w-full p-5 text-right h-full">
                    <Adsense
                        data-ad-client="ca-pub-9750567100834191"
                        slot="2679096007"
                        style={{ display: 'block', position: 'sticky', top: '90px' }}
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    />
                </div>
            </div>
        </div>
    )
}