import { Link } from "react-router-dom";

export const CTAButton = () => {
    const CTAOptions = [
      "Discover Equitable Budgeting",
      "Get Started with Equitable Budgets",
      "Try Our Equitable Calculator"
    ];
  
    const getRandomCTA = () => {
      const randomIndex = Math.floor(Math.random() * CTAOptions.length);
      return CTAOptions[randomIndex];
    };
  
    return (
        <Link className="m-auto" to={'/'}>
            <button className="btn btn-primary text-white rounded-full btn-sm shadow btn-wide mt-6 h-auto py-3 normal-case">{getRandomCTA()}</button>
        </Link>
    );
};