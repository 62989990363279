import { createSlice } from "@reduxjs/toolkit";

export const selectCurrencySlice = createSlice({
    name: 'selectCurrency',
    initialState: {
        value: '$'
    },
    reducers: {
        updateCurrency: (state, c) => {
            state.value = c.payload;
        }
    }
});

export const { updateCurrency } = selectCurrencySlice.actions;
export default selectCurrencySlice.reducer;