function DefaultButton({children, onClick, color}) {
    return (
        <button
            className="btn btn-primary text-white rounded-full btn-sm shadow h-auto py-3 normal-case"
            onClick={onClick}
            style={{'background': `var(${color})`}}
        >
            {children}
        </button>
    )
}

export default DefaultButton;