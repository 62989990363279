import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.scss';
import './index.css';
import './input.css';
import reportWebVitals from './reportWebVitals';
import store from "./store/store";
import {Provider} from "react-redux";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Root from './routes/root';
import PrivacyPolicy from './routes/privacy-policy';
import Home, { homePageLoader } from './routes/home';
import PercentageCalculator from './routes/percentage-calculator';
import Calculator50_30_20 from './routes/50-30-20-calculator';
import About from './routes/about';
import BlogList, { blogListLoader } from './routes/blog-list';
import BlogPage, { blogPostLoader } from './routes/blog-page';
import { Outlet } from 'react-router-dom';
import ErrorPage from './routes/error-page';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Home />,
                loader: homePageLoader,
                children: [{
                    path: '/*',
                }]
            },
            {
                path: "/50-30-20-calculator",
                element: <Calculator50_30_20/>
            },
            {
                path: "/percentage-calculator",
                element: <PercentageCalculator />
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicy />
            },
            {
                path: '/blog',
                elements: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <BlogList/>,
                        loader: blogListLoader,
                    },
                    {
                        element: <BlogList/>,
                        path: ':category',
                        loader: ({ params }) => {
                            return blogListLoader(params.category)
                        }
                    },
                    {
                        path: '/blog/post',
                        elements: <Outlet />,
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: ":slug",
                                element: <BlogPage />,
                                loader: ({ params }) => {
                                    return blogPostLoader(params.slug);
                                },
                            },
                        ]
                    },
                ]
            },
            {
                path: "/about",
                element: <About />
            },
            {
                path: "*",
                element: <Home />,
            }
          ],
    },
], {
    basename: '/'
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
