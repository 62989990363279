import { useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { isMobileDevice } from '../mixins/deviceChecker';
import { Link } from 'react-router-dom';
import SkeletonCard from '../components/Skeleton/ArticleCard';
import ArticleCard from '../components/ArticleCard/ArticleCard';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import updateMetaDescription from '../mixins/updateMetaDescription';
import { categoryMetaDesc } from '../data/defaults';
import { Adsense } from '@ctrl/react-adsense';

export const blogListLoader = async (params) => {
    if (!params.length) {
        params = ''
    }
    
    const urls = [
        `https://tinyarcade-cms-8n6yr.ondigitalocean.app/api/fsc-articles?filters[category][url][$contains]=${params}&sort=publishedAt:desc&populate=*`,
        'https://tinyarcade-cms-8n6yr.ondigitalocean.app/api/fsc-categories'
    ]

    const [articlesResponse, categoriesResponse] = await Promise.all(urls.map(url => fetch(url).then(response => response.json())));
    return { articles: articlesResponse, categories: categoriesResponse };
}
  

export default function BlogList() {
	const [skeletonElements, setSkeletonElements] = useState()
    const { category: activeCategory } = useParams();

	useEffect(() => {
		isMobileDevice() ? setSkeletonElements(3) : setSkeletonElements(8)
	})

    const data = useLoaderData();

    function getLabelByUrl() {
        const item = data.categories.data.find(category => category.url === activeCategory);
        return item ? `– ${item.label}` : '– Blog';
    }

    if (!activeCategory) {
        let defaultMetaDesc = 'Explore comprehensive financial insights covering budgeting tips, money-saving strategies, and expert advice on financial planning. Learn how to smartly calculate income, optimize bills, manage rent, and other expenses for a secure financial future.'
        updateMetaDescription(defaultMetaDesc)
    } else {
        updateMetaDescription(categoryMetaDesc[activeCategory])
    }
    
    return (
        <div className="m-auto p-5">
            <HelmetProvider>
                <Helmet>
                    <title>Fair Split Calculator {getLabelByUrl()}</title>
                </Helmet>
            </HelmetProvider>

			<div className='flex m-auto lg:grid grid-cols-[1fr_800px_1fr] max-w-[1400px]'>
                <div className="hidden lg:block w-full p-5 text-right h-full">
                    <Adsense
                        data-ad-client="ca-pub-9750567100834191"
                        slot="2679096007"
                        style={{ display: 'block', position: 'sticky', top: '90px' }}
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    />
                </div>

                <div className='w-full max-w-[800px] m-auto'>
                    <section className="mb-4 flex flex-col items-center">
						<h1 className="text-4xl text-center font-bold mb-4">Blog</h1>
					</section>
					{(data) ? (
						<section className='mb-12'>
							<div className="flex flex-wrap gap-x-2 justify-center mb-12">
								<Link to={'/blog'} className={!activeCategory ? 'font-bold text-primary underline' : ''}>All</Link>
								{data.categories.data.map((category, index) => (
									<Link to={'/blog/' + category.url} className={`${activeCategory === category.url ? 'font-bold text-primary underline' : ''}`} key={index}>{category.label}</Link>
								))}
							</div>
							<div className='flex flex-col gap-y-5 max-w-[460px] md:max-w-none m-auto md:grid md:grid-cols-2 md:gap-5'>
								{data.articles.data.map((article, index) => (
									<ArticleCard article={article} key={index} />
								))}
							</div>
						</section>
					) : ( // Skeleton Loaders
						<section className='mb-12 flex flex-col min-h-[100vh]'>
							<div className="animate-pulse flex flex-wrap justify-center w-full gap-2 mb-12">
								<div className="h-4 bg-gray-200 rounded-full dark:bg-gray-300 w-[150px]"></div>
								<div className="h-4 bg-gray-200 rounded-full dark:bg-gray-300 w-[280px]"></div>
							</div>

							<div className='flex flex-col gap-y-5 md:grid md:grid-cols-2 md:gap-5'>
								{[...Array(skeletonElements)].map((_, index) => (
									<SkeletonCard key={index} />
								))}
							</div>
						</section>
					)}
				</div>

				<div className="hidden lg:block w-full p-5 text-right h-full">
                    <Adsense
                        data-ad-client="ca-pub-9750567100834191"
                        slot="2679096007"
                        style={{ display: 'block', position: 'sticky', top: '90px' }}
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    />
                </div>
            </div>
        </div>
    )
}