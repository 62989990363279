import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import {useSelector} from "react-redux";
import { Link } from 'react-router-dom';
import bgGraphic from '../assets/bg-vector-graphic.svg'
import updateMetaDescription from '../mixins/updateMetaDescription';

export default function Calculator50_30_20() {
	const [salaryIncome, setSalaryIncome] = useState(0);
    const [otherIncome, setOtherIncome] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [needs, setNeeds] = useState(0);
    const [wants, setWants] = useState(0);
    const [savings, setSavings] = useState(0);
    const [isAnnual, setIsAnnual] = useState(false);
    const currency = useSelector(state => state.currency.value)
	updateMetaDescription('Start your budgeting journey with our 50/30/20 Budgeting Calculator. Manage your finances by splitting your income into needs, wants, and savings.')

    useEffect(() => {
        const needsPercentage = 50;
        const wantsPercentage = 30;
        const savingsPercentage = 20;

        const annualMultiplier = isAnnual ? 12 : 1;
        let calculatedNeeds = (needsPercentage / 100) * totalIncome * annualMultiplier;
        let calculatedWants = (wantsPercentage / 100) * totalIncome * annualMultiplier;
        let calculatedSavings = (savingsPercentage / 100) * totalIncome * annualMultiplier;

        // Check for NaN and fallback to 0
        calculatedNeeds = isNaN(calculatedNeeds) ? 0 : calculatedNeeds;
        calculatedWants = isNaN(calculatedWants) ? 0 : calculatedWants;
        calculatedSavings = isNaN(calculatedSavings) ? 0 : calculatedSavings;

        setNeeds(calculatedNeeds);
        setWants(calculatedWants);
        setSavings(calculatedSavings);

        if (isNaN(salaryIncome)) {
            setSalaryIncome(0);
        }
        if (isNaN(otherIncome)) {
            setOtherIncome(0);
        }

        setTotalIncome(salaryIncome + otherIncome);
    }, [salaryIncome, otherIncome, totalIncome, isAnnual]);

    function handleToggleChange() {
        setIsAnnual((prevIsAnnual) => !prevIsAnnual);
    }

    return (
		<div>
			<HelmetProvider>    
				<Helmet>
					<title>50/30/20 Budget Plan Calculator – Fair Split Calculator</title>
				</Helmet>
			</HelmetProvider>

			<section className="m-auto max-w-[480px] md:max-w-[800px] p-5 mb-6">
				<div className="mb-4 flex flex-col max-w-[600px] m-auto">
					<h1 className='text-3xl md:text-4xl text-center font-bold mb-4'>50/30/20 Budget Plan Calculator</h1>
					<p className='text-center mb-2'>Manage your finances by splitting your income into needs, wants, and savings using our 50/30/20 Budgeting Calculator.</p>
				</div>

				<div className="mb-12 flex flex-col max-w-[600px] m-auto gap-4 items-center">
					<div className="card w-full max-w-[440px] shadow-xl bg-white">
						<div className="card-body px-4">
							<h2 className="card-title text-lg md:text-xl justify-self-center mb-2">Household Income (After Taxes)</h2>
							<div className='min-[420px]:flex'>
								<div className="label w-full sm:max-w-[65%]">
									<span className="label-text">Salary/Wages (As Household)</span>
								</div>

								<label className="form-control w-full sm:max-w-[200px]">
									<input
										type="number"
										placeholder="0"
										className="input input-bordered sm:ml-2"
										step={10}
										min={0}
										onChange={(e) => {
											setSalaryIncome(parseFloat(e.target.value));
										}}
									/>
								</label>
							</div>

							<div className='min-[420px]:flex'>
								<div className="label w-full sm:max-w-[65%]">
									<span className="label-text">Other Sources Of Income:</span>
								</div>
								<label className="form-control w-full sm:max-w-[200px]">
									<input
										type="number"
										placeholder="0"
										className="input input-bordered sm:ml-2"
										step={10}
										min={0}
										onChange={(e) => {
											setOtherIncome(parseFloat(e.target.value));
										}}
									/>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className='mb-4 flex flex-col items-center'>
					<div className='grid grid-cols-3 bg-primary w-full rounded-xl py-6 md:w-[500px] mb-4'>
						<div className='text-center text-white border-r'>
							<p className='text-xs sm:text-sm mb-2'><span className='font-bold'>Needs</span> <span className='tooltip before:w-[220px] sm:before:w-[320px] before:left-10 before:content-[attr(data-tip)] cursor-pointer' data-tip="Needs are the essential costs cruicial to your survival, like rent, utilities, groceries, and transportation.">ⓘ</span></p>
							<p className='text-base sm:text-lg md:text-2xl font-bold'>{currency} {needs.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: needs % 1 !== 0 ? 2 : 0 })}</p>
						</div>

						<div className='text-center text-white border-r'>
							<p className='text-xs sm:text-sm mb-2'><span className='font-bold'>Wants</span> <span className='tooltip before:w-[220px] sm:before:w-[320px] before:content-[attr(data-tip)] cursor-pointer' data-tip="Wants are all non-essential expenses, items or services that you choose to spend money on but could live without if necessary.">ⓘ</span></p>
							<p className='text-base sm:text-lg md:text-2xl font-bold'>{currency} {wants.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: wants % 1 !== 0 ? 2 : 0 })}</p>
						</div>

						<div className='text-center text-white'>
							<p className='text-xs sm:text-sm mb-2'><span className='font-bold'>Savings</span> <span className='tooltip before:w-[220px] sm:before:w-[320px] before:-left-20 before:right-0 before:content-[attr(data-tip)] cursor-pointer' data-tip="This is the amount to put towards your short or long term savings goals like an emergency fund, retirement plan or outstanding debt.">ⓘ</span></p>
							<p className='text-base sm:text-lg md:text-2xl font-bold'>{currency} {savings.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: savings % 1 !== 0 ? 2 : 0 })}</p>
						</div>
					</div>

					<div className="form-control w-fit">
						<label className="label cursor-pointer flex gap-x-4">
							<span className="label-text text-xs text-center">Calculate Monthly</span> 
							<input type="checkbox" className="toggle border-solid border-slate-200" checked={isAnnual} onChange={handleToggleChange} />
							<span className="label-text text-xs text-center">Calculate Annually</span> 
						</label>
					</div>
				</div>
			</section>

			<section className='w-full pb-8 pt-12 bg-repeat-x bg-cover xl:bg-contain mb-12' style={{ backgroundImage: `url(${bgGraphic})` }}>
                <div className="w-full m-auto p-5 max-w-[800px] mb-12 flex flex-col" >
					<h2 className='text-3xl md:text-4xl font-bold mb-6 text-center'>What Is The 50/30/20 Budget Plan?</h2>

                    <div className="card w-full max-w-[800px] shadow-xl bg-white m-auto">
						<div className="card-body px-6">
							<h3 className='font-bold text-lg'>Understanding the 50/30/20 Rule</h3>
							<p>The 50/30/20 rule is a simple budgeting guideline that suggests allocating 50% of your after-tax income into needs, 30% into wants, and 20% into savings. This balance helps to promote financial stability and ensures that your current needs are met whilst simultaneously saving for the future.</p>
							<h3 className='font-bold text-lg mt-3'>Benefits of the 50/30/20 Rule</h3>
							<p>The 50/30/20 rule provides a clear framework to manage money, ensuring financial stability by dividing income into needs, wants, and savings. This approach helps reach specific savings goals, reduces debt, and makes budgeting easier. The rule also encourages thinking ahead for long-term financial plans like saving for retirement, investments, or a house.</p>
							<Link to={'/blog/post/first-time-making-a-budget-try-the-503020-budgeting-rule'} className='font-bold mt-4 self-end'>Read More →</Link>
						</div>
					</div>
                </div>
            </section>

			<section className='w-full m-auto p-5 max-w-[800px]'>
                <h2 className='text-3xl md:text-4xl text-center font-bold mb-8'>Frequently Asked Questions</h2>
                <div className="join join-vertical w-full bg-[#EEEEF8] mb-12">
                    <div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4" /> 
                        <div className="collapse-title font-bold">What is the 50/30/20 rule?</div>
                        <div className="collapse-content"> 
                            <p>The 50/30/20 rule is a popular budgeting strategy to help people manage their finances effectively. It suggests using 50% of your after-tax income for essential needs, like housing and utilities, 30% for wants, such as dining out or entertainment, and dedicating the remaining 20% to your savings.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4"/>
                        <div className="collapse-title font-bold">How do I determine my needs?</div>
                        <div className="collapse-content"> 
                            <p>Identifying your needs involves recognizing the essential expenses crucial for your day-to-day survival. This includes expenditures like housing or rent, utilities such as electricity and water, groceries, insurance coverage, healthcare costs, and transportation expenses.</p>
                        </div>
                    </div>
					<div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4"/>
                        <div className="collapse-title font-bold">What falls under the category of wants?</div>
                        <div className="collapse-content"> 
                            <p>Wants are the non-essential and optional expenditures that contribute to an enjoyable lifestyle. These may involve dining out at restaurants, subscribing to services like streaming platforms, or purchasing indulgent luxury items that go beyond your basic necessities.</p>
                        </div>
                    </div>
					<div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4"/>
                        <div className="collapse-title font-bold">Can I adjust the percentages based on my circumstances?</div>
                        <div className="collapse-content"> 
							<p>Of course! While the 50/30/20 rule works effectively with its original allocation, it also serves as a template that can be adjusted to fit your individual circumstances. Feel free to adjust the allocations to find a solution that best suits your lifestyle and financial goals.</p>
                        </div>
                    </div>
					<div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4"/>
                        <div className="collapse-title font-bold">Is the 50/30/20 rule suitable for irregular income?</div>
                        <div className="collapse-content"> 
                            <p>Yes, the rule accommodates irregular income. Calculate the average monthly earnings over the last 3-6 months to determine the appropriate percentages for needs, wants, and savings, providing a consistent framework for budgeting.</p>
                        </div>
                    </div>
					<div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4"/>
                        <div className="collapse-title font-bold">How often should I review and adjust my budget?</div>
                        <div className="collapse-content"> 
                            <p>Regular reviews are key, especially with significant changes in income or expenses. Periodic adjustments ensure that your budget remains aligned with your evolving financial goals and accommodates any shifts in your financial landscape.</p>
                        </div>
                    </div>
					<div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4"/>
                        <div className="collapse-title font-bold">Is the 50/30/20 rule suitable for long-term financial planning?</div>
                        <div className="collapse-content"> 
                            <p>Yes. The rule encourages a forward-thinking approach to financial planning. By incorporating savings for long-term goals like retirement, major purchases, or debt reduction, it establishes a solid foundation for sustained financial well-being beyond immediate needs.</p>
                        </div>
                    </div>
                </div>
            </section>
		</div>
    )
}