import { Link } from "react-router-dom"
import dayjs from 'dayjs';

export default function ArticleCard({article}) {
    const date = dayjs(article?.publishedAt).format("DD MMMM, YYYY")
    return (
        <Link to={'/blog/post' + article.slug}>
            <div className="shadow hover:shadow-lg group rounded-2xl overflow-hidden bg-white h-full flex flex-col">
                <div className="h-[200px] w-full" >
                    <div className="h-full w-full overflow-hidden">
                        <img className="h-full w-full object-cover group-hover:scale-105 transition-transform" src={article.cover_image.url} alt="" />
                    </div>
                </div>
                <div className="flex flex-col gap-y-2 p-5 h-full">
                    <p className="uppercase font-bold text-primary">{article.category.label}</p>
                    <h3 className="text-2xl font-bold h-full">{article.title}</h3>
                    {/* <p>{date}</p> */}
                </div>
            </div>
        </Link>
    )
}
