export const colors = [
    {
        primary: 'cadetblue',
        secondary: 'mediumorchid',
        background: 'ghostwhite',
    },
    {
        primary: '#445543',
        background: '#BECCA1',
    },
    {
        primary: '#47537D',
        background: '#A1B7CC'
    },
    {
        primary: 'red',
        background: 'pink'
    }
];
