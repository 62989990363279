import './result-box.styles.scss';
import {isMobileDevice} from "../../mixins/deviceChecker";
import {useSelector} from "react-redux";

function ResultBox({result, index}) {
    const currency = useSelector(state => state.currency.value)
    return (
        <div className={`result-box ${isMobileDevice() ? '__small' : ''}`}>
            <span className="label">{result.label ? result.label : `Person ${index + 1}`}</span>
            <p className="amount">{currency} {result.rental_share ? result.rental_share : '0.00'}</p>
            <span className="text">per month</span>
            <span className="percentage">
                {result.rental_share && result.percentage
                    ? result.percentage
                    : '0.0%'
                }
            </span>
        </div>
    )
}

export default ResultBox;