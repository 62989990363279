import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import currencyReducer from "../helpers/selectCurrency.slice";

const reducer = combineReducers({
    currency: currencyReducer,
});

const store = configureStore({
    reducer,
});

export default store;