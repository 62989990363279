export default function updateMetaDescription(text) {
    const secondPeriodIndex = text.indexOf('.', text.indexOf('.') + 1);
    let metaText = text;

    if (secondPeriodIndex !== -1) {
        metaText = text.substring(0, secondPeriodIndex + 1);
    } else {
        metaText = text;
    }

    let metaTag = document.querySelector('meta[name="description"]');
    
    if (metaTag) {
        metaTag.setAttribute('content', metaText);
    }
}