import { Link } from "react-router-dom"
import pageTitle from '../../assets/fairsplitcalc_title.png';
import CurrencySelector from "../CurrencySelector/CurrencySelector.component";
import { navItems } from "../../data/navigation";

export default function GlobalNav() {
    return (
        <div className="bg-[#f8f8ff] z-50 shadow-sm sticky top-0">
            <div className="navbar m-auto max-w-[800px] relative px-5">
                <div className="navbar-start w-full justify-center lg:justify-start">
                    <CurrencySelector />

                    <a href="/" className="w-[220px] md:w-[280px] ml-[44px] lg:ml-[60px] mb-1">
                        <img src={pageTitle} alt="FairSplitCalculator Logo" />
                    </a>
                </div>
                <div className="navbar-center hidden lg:flex">
                    <ul className="menu menu-horizontal px-1 text-sm">
                        {navItems.map((item, index) => (
                            <li key={index}>
                                {item.dropdown_items ? (
                                    <div className="dropdown dropdown-bottom">
                                        <label className="cursor-pointer" tabIndex={0}>{item.label}</label>
                                        <ul tabIndex={0} className="ml-0 mt-2 dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box min-w-[200px]">
                                            {item.dropdown_items.map((dropdownItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <Link to={dropdownItem.url}>{dropdownItem.label}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <Link to={item.url}>{item.label}</Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="navbar-end w-auto">
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-neutral btn-circle lg:hidden w-[36px] h-[36px] max-w-[36px] max-h-[36px] p-0 min-h-[36px]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <ul tabIndex={0} className="menu menu-sm dropdown-content mt-2 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                            {navItems.map((item, index) => (
                                item.dropdown_items ? (
                                    item.dropdown_items.map((dropdownItem, subIndex) => (
                                        <li key={subIndex} className="h-11">
                                            <Link className="leading-tight h-full items-center flex" to={dropdownItem.url}>{dropdownItem.label}</Link>
                                        </li>
                                    ))
                                ) : (
                                    <li key={index} className="h-11">
                                        <Link className="leading-tight h-full items-center flex" to={item.url}>{item.label}</Link>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}