import './currency-selector.styles.scss'
import {useState} from "react";
import {currencyList} from "../../data/defaults";
import {useSelector, useDispatch} from "react-redux";
import {updateCurrency} from "../../helpers/selectCurrency.slice";

function CurrencySelector() {
    const [showSelector, setShowSelector] = useState(false);
    const currency = useSelector(state => state.currency.value)
    const dispatch = useDispatch()

    return (
        <div className="currency-selector" onMouseEnter={() => setShowSelector(true)}
             onMouseLeave={() => setShowSelector(false)}>
            <button
                className="button bg-primary"
                onClick={() => setShowSelector(!showSelector)}
            >{currency}</button>
            { showSelector &&
                <div className="drop-down">
                    {currencyList.map((el, i) => (
                        <div
                            className={`option ${currency === el && '__selected'}`}
                            onClick={() => {
                                dispatch(updateCurrency(el));
                                setShowSelector(false)
                            }}
                            key={i}
                        >
                            {el}
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default CurrencySelector;