import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <p className='copyright'>© 2023 – {new Date().getFullYear()} All Rights Reserved</p>
            <ul>
                <li>
                    <Link to={'/'}>Home</Link>
                </li>
                <li>
                    <Link to={'/blog'}>Blog</Link>
                </li>
                <li>
                    <Link to={`/privacy-policy`}>Privacy Policy</Link>
                </li>
            </ul>
        </footer>
    )
}