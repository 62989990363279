export const navItems = [
    {
        label: 'Calculators',
        dropdown_items: [
            {
                label: 'Equitable Expenses Calculator',
                url: '/',
            },
            {
                label: 'Percentage Calculator',
                url: '/percentage-calculator',
            },
            {
                label: '50/30/20 Budget Calculator',
                url: '/50-30-20-calculator',
            }
        ]
    },
    {
        label: 'Blog',
        url: '/blog',
    },
    {
        label: 'About',
        url: '/about',
    },
]