import './input-field.styles.scss'
import {useSelector} from "react-redux";

function InputField({showLabel, label, labelPlaceholder, placeholder, onChange, type = 'number', value = '', style}) {
    let inputSize = label?.length === 0 ? labelPlaceholder.length : label.length;
    const currency = useSelector(state => state.currency.value)

    return (
        <div className={'input-field'} style={style}>
            <span className="prefix">{currency}</span>

            { showLabel && labelPlaceholder &&
                <input
                    className="label"
                    type="text"
                    value={label}
                    placeholder={labelPlaceholder}
                    size={inputSize}
                    onChange={onChange}
                />
            }

            <input
                className="input input-bordered"
                name={label}
                step={100}
                min={0}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                style={{paddingRight: label ? '65px' : '25px'}}
                tabIndex={1}
            />
        </div>
    )
}

export default InputField;