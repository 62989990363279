import aboutPageGraphic from '../assets/about_page-graphic.svg';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import updateMetaDescription from '../mixins/updateMetaDescription';
import { CTAButton } from '../components/CTAButton/CTAButton';

export default function About() {
    updateMetaDescription('Fair Split Calculator simplifies shared expenses with user-friendly tools, promoting financial fairness and consideration. Navigate complexities, foster harmony, and achieve equitable solutions tailored to individual finances. Join us for peace in shared spaces.')

    return (
        <div className="m-auto max-w-[800px] p-5">
            <HelmetProvider>
                <Helmet>
                    <title>Fair Split Calculator – About</title>
                </Helmet>
            </HelmetProvider>
            <section className="mb-12 flex flex-col items-center">
                <h1 className="text-4xl text-center font-bold mb-4">About Fair Split Calculator</h1>
                <h2 className="text-center max-w-[600px] mb-8">Fair Split Calculator is committed to simplifying the often complex process of managing shared expenses.</h2>
                <img src={aboutPageGraphic} alt="A couple standing together smiling" />
            </section>
            <section className='mb-12'>
                <div className="flex flex-col gap-y-4 max-w-[600px] m-auto bg-[#EEEEF8] rounded-xl p-5 md:p-10 leading-7">
                    <p>We aim to provide user-friendly tools that enable individuals, roommates, or cohabitants to manage their expenses equitably, fostering fairness and consideration in financial matters.</p>
                    <p>We understand the need for equitable solutions in shared financial matters, and we're here to help others navigate these challenges.</p>
                    <p>Our commitment to fairness, consideration, and transparency drives us to provide you with the best possible tools and resources for managing your finances and expenses.</p>
                    <p>With Fair Split Calculator, you can ensure that shared expenses are calculated in a way that respects each individual's financial situation, fostering fairness and peace in shared living spaces.</p>
                    <CTAButton />
                </div>
            </section>
        </div>
    );
}